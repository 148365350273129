jQuery(document).ready(function() {
function GetCookie(name) {
    var arg=name+"=";
    var alen=arg.length;
    var clen=document.cookie.length;
    var i=0;
    while (i<clen) {
        var j=i+alen;

        if (document.cookie.substring(i,j)==arg)
            return "1";
        i=document.cookie.indexOf(" ",i)+1;
        if (i==0)
            break;
    }

    return null;
}



jQuery(function() {
    var visit=GetCookie("cookies_bpc");
    if (visit==1){
        $('.cookies').toggle();
    }

});

    $(document).on("click","#cookies_action",function(){
        $('.cookies').toggle();
        var expire=new Date();
        expire=new Date(expire.getTime()+7776000000);
        document.cookie="cookies_bpc=accept; expires="+expire;

        var visit=GetCookie("cookies_bpc");
        if (visit==1){
            popbox();
        }
    });

});